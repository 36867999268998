html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: aktiv-grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
  color: #fff;
  background-color: #000000;
  font-weight: 400;
  font-style: normal;
  height: -webkit-fill-available;
  font-display: swap;
  touch-action: none
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html {
  --scroll: rgba(255,255,255,0);
}

.ReactModal__Content--after-open {
  overflow: overlay !important;  
}

.ReactModal__Content--after-open::-webkit-scrollbar {
  width: 7px;
  border-radius: 5px;

  pointer-events: all;

}

.ReactModal__Content--after-open::-webkit-scrollbar-thumb {
  background-color: var(--scroll);
  border-radius: 5px;
}
  
.ReactModal__Content--after-open {
  z-index: 1;
  overflow: overlay !important;  
}

@media (hover: none) {

  .ReactModal__Content--after-open::-webkit-scrollbar {
    display: none;
  }

}
